import { isLoggedIn } from '@utils/auth'
import { getLocationsApi } from './api'
export const state = {
  locations: [],
}

export const getters = {
  locations(state) {
    return state.locations
  },
  locationOptions(state) {
    return state.locations
  },
}

export const mutations = {
  SET_LOCATIONS(state, data) {
    state.locations = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return getLocationsApi(false, params).then((data) => {
      commit('SET_LOCATIONS', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_LOCATIONS', [])
  },
}
