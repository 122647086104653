import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('location')

export const LocationComputed = {
  ...mapGetters(['locationOptions']),
}

export const LocationMethods = {
  ...mapActions({
    fetchLocation: 'fetch',
  }),
}
