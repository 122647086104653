import api from '@api'
import { transformAttachment } from '@data/attachment'
import { getRootTranslator } from '@utils/get-module-translator'
import Moment from 'moment'

const __rootT = getRootTranslator()

const transformBranding = (branding) => {
  return {
    id: branding.id,
    name: branding.name,
    logo: branding.logo ? [transformAttachment(branding.logo)] : undefined,
    darkThemeLogo: branding.darkThemeLogo
      ? [transformAttachment(branding.darkThemeLogo)]
      : undefined,
    email: branding.email,
    contactNumber: branding.contactNumber,
    technicianPortalTitle: branding.technicianPortalTitle,
    customerPortalTitle: branding.customerPortalTitle,
    loginScreenPreference: branding.loginScreenPreference,
    ...(branding.logo ? { logoSrc: '/api/public/download/logo' } : {}),
    ...(branding.darkThemeLogo
      ? {
          darkThemeLogoSrc: '/api/public/download/dark/theme/logo',
        }
      : {}),
    favicon: branding.favicon
      ? [transformAttachment(branding.favicon)]
      : undefined,
    ...(branding.favicon
      ? {
          faviconSrc: `/api/public/download/fevicon?v=${Moment().unix()}`,
        }
      : {}),
    themeColorPalette: branding.themeColorPalette
      ? JSON.parse(branding.themeColorPalette)
      : '',
    pagesCustomization: branding.pagesCustomization,
  }
}

const transformBrandingForServer = (branding) => {
  return {
    id: branding.id,
    name: branding.name,
    email: branding.email,
    contactNumber: branding.contactNumber,
    logo: branding.logo && branding.logo.length ? branding.logo[0] : undefined,
    darkThemeLogo:
      branding.darkThemeLogo && branding.darkThemeLogo.length
        ? branding.darkThemeLogo[0]
        : undefined,
    technicianPortalTitle: branding.technicianPortalTitle,
    customerPortalTitle: branding.customerPortalTitle,
    loginScreenPreference: branding.loginScreenPreference,
    favicon:
      branding.favicon && branding.favicon.length
        ? branding.favicon[0]
        : undefined,
    themeColorPalette: branding.themeColorPalette
      ? JSON.stringify(branding.themeColorPalette)
      : '',
    pagesCustomization: branding.pagesCustomization,
  }
}

export function getBrandingApi(params = {}) {
  return api
    .get('/public/branding', {
      notify: false,
      params: params,
    })
    .then(transformBranding)
}

export function updateBrandingApi(data) {
  return api
    .patch('/branding', transformBrandingForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootT('branding'),
      }),
    })
    .then(transformBranding)
}
