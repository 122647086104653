import moment from 'moment'
import api from '@api'
import { transformUser } from '@data/user'
import { stringify } from '@utils/stringify-json'
import { getRootTranslator } from '@utils/get-module-translator'
import { isPortalLogin } from '@utils/auth'

export function loginApi(username, password, aditionalParams = {}) {
  return api
    .post(
      '/oauth/token',
      stringify({
        username,
        password,
        grant_type: 'password',
      }),
      {
        headers: {
          Authorization: `Basic ${process.env.VUE_APP_CLIENT_ID}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...aditionalParams,
        },
        notify: false,
      }
    )
    .then((response) => {
      response.expires_at = moment()
        .add(response.expires_in - 10, 's')
        .unix()
      return response
    })
}

export function logoutApi() {
  return api.post('/user/logout', {
    notify: false,
  })
}

export function getUserApi() {
  return api
    .get(`${isPortalLogin() ? '/requester' : '/technician'}/me`)
    .then(transformUser)
}

export function getUserProfileApi() {
  return api.get('/user/profile').then((data) => {
    return {
      securityQuestions: (data.securityQuestions || []).map((m) => {
        return {
          id: m.id,
          name: m.key,
        }
      }),
      displaySecurityPopup: data.displaySecurityPopup,
    }
  })
}

export function updateUserProfileApi(data) {
  return api.patch('/user/profile', data).then((data) => {
    return {
      securityQuestions: data.securityQuestions,
    }
  })
}

export function changePasswordApi(data) {
  const __rootT = getRootTranslator()
  return api.post(
    '/user/changepassword/',
    {
      currentPassword: data.currentPassword,
      password: data.password,
    },
    {
      message: __rootT('updated_successfully', {
        resource: __rootT('password'),
      }),
    }
  )
}
