import { isLoggedIn } from '@utils/auth'
import { getAssetsApi } from './api'
export const state = {
  assetTypes: [],
}

export const getters = {
  assetTypes(state) {
    return state.assetTypes
  },
}

export const mutations = {
  SET_ASSET_TYPE(state, data) {
    state.assetTypes = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }) {
    return getAssetsApi().then((data) => {
      commit('SET_ASSET_TYPE', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_ASSET_TYPE', [])
  },
}
