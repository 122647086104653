import { createNamespacedHelpers } from 'vuex'
import Constants from '@constants'

const { mapGetters: requestGetters, mapActions: requestActions } =
  createNamespacedHelpers(`form/${Constants.REQUEST}`)
const { mapGetters: problemGetters, mapActions: problemActions } =
  createNamespacedHelpers(`form/${Constants.PROBLEM}`)
const { mapGetters: changeGetters, mapActions: changeActions } =
  createNamespacedHelpers(`form/${Constants.CHANGE}`)
const { mapGetters: projectGetters, mapActions: projectActions } =
  createNamespacedHelpers(`form/${Constants.PROJECT}`)
const { mapGetters: releaseGetters, mapActions: releaseActions } =
  createNamespacedHelpers(`form/${Constants.RELEASE}`)
const { mapGetters: assetGetters, mapActions: assetActions } =
  createNamespacedHelpers(`form/${Constants.ASSET}`)

const { mapGetters: userGetters, mapActions: userActions } =
  createNamespacedHelpers(`form/${Constants.USER}`)

const { mapGetters: feedbackGetters, mapActions: feedbackActions } =
  createNamespacedHelpers(`form/${Constants.FEEDBACK}`)
const { mapGetters: productGetters, mapActions: productActions } =
  createNamespacedHelpers(`form/${Constants.PRODUCT}`)

const { mapGetters: vendorGetters, mapActions: vendorActions } =
  createNamespacedHelpers(`form/${Constants.VENDOR}`)

const { mapGetters: contractGetters, mapActions: contractActions } =
  createNamespacedHelpers(`form/${Constants.CONTRACT}`)

const { mapGetters: purchaseGetters, mapActions: purchaseActions } =
  createNamespacedHelpers(`form/${Constants.PURCHASE}`)

// const { mapGetters: callHistoryGetters, mapActions: callHistoryActions } =
//   createNamespacedHelpers(`form/${Constants.CO_OPS_CALL_HISTORY}`)

const { mapGetters: mspCompanyGetters, mapActions: mspCompanyActions } =
  createNamespacedHelpers(`form/${Constants.MSP_COMPANY}`)

export const FormComputed = {
  ...requestGetters({
    requestLoading: 'loading',
    requestPortalFields: 'portalFields',
    requestForm: 'form',
    requestAttachmentFields: 'attachmentFields',
    requestFields: 'getFields',
    requestHiddenFields: 'hiddenFields',
  }),
  ...problemGetters({
    problemLoading: 'loading',
    problemForm: 'form',
    problemAttachmentFields: 'attachmentFields',
    problemFields: 'getFields',
    problemHiddenFields: 'hiddenFields',
  }),
  ...changeGetters({
    changeLoading: 'loading',
    changeForm: 'form',
    changeAttachmentFields: 'attachmentFields',
    changeFields: 'getFields',
    changeHiddenFields: 'hiddenFields',
  }),
  ...projectGetters({
    projectLoading: 'loading',
    projectAttachmentFields: 'attachmentFields',
    projectFields: 'getFields',
    projectForm: 'form',
  }),
  ...releaseGetters({
    releaseLoading: 'loading',
    releaseForm: 'form',
    releaseAttachmentFields: 'attachmentFields',
    releaseFields: 'getFields',
    releaseHiddenFields: 'hiddenFields',
  }),
  ...assetGetters({
    assetLoading: 'loading',
    assetForm: 'form',
    assetAttachmentFields: 'attachmentFields',
    assetFields: 'getFields',
  }),
  ...userGetters({
    userLoading: 'loading',
    userForm: 'form',
    userAttachmentFields: 'attachmentFields',
    userFields: 'getFields',
  }),
  ...feedbackGetters({
    feedbackLoading: 'loading',
    feedbackForm: 'form',
    feedbackAttachmentFields: 'attachmentFields',
    feedbackFields: 'getFields',
  }),
  ...productGetters({
    productLoading: 'loading',
    productForm: 'form',
    productAttachmentFields: 'attachmentFields',
    productFields: 'getFields',
  }),
  ...vendorGetters({
    vendorLoading: 'loading',
    vendorForm: 'form',
    vendorAttachmentFields: 'attachmentFields',
    vendorFields: 'getFields',
  }),
  ...contractGetters({
    contractLoading: 'loading',
    contractPortalFields: 'portalFields',
    contractForm: 'form',
    contractAttachmentFields: 'attachmentFields',
    contractFields: 'getFields',
  }),
  ...purchaseGetters({
    purchaseLoading: 'loading',
    purchasePortalFields: 'portalFields',
    purchaseForm: 'form',
    purchaseAttachmentFields: 'attachmentFields',
    purchaseFields: 'getFields',
  }),
  // ...callHistoryGetters({
  //   co_ops_call_historyLoading: 'loading',
  //   co_ops_call_historyPortalFields: 'portalFields',
  //   // co_ops_call_historyForm: 'form',
  //   co_ops_call_historyAttachmentFields: 'attachmentFields',
  //   co_ops_call_historyFields: 'getFields',
  // }),
  ...mspCompanyGetters({
    msp_companyLoading: 'loading',
    msp_companyForm: 'form',
    msp_companyAttachmentFields: 'attachmentFields',
    msp_companyFields: 'getFields',
  }),
}

export const FormActions = {
  ...requestActions({
    fetchRequestForm: 'fetch',
  }),
  ...problemActions({
    fetchProblemForm: 'fetch',
  }),
  ...changeActions({
    fetchChangeForm: 'fetch',
  }),
  ...projectActions({
    fetchProjectForm: 'fetch',
  }),
  ...releaseActions({
    fetchReleaseForm: 'fetch',
  }),
  ...userActions({
    fetchUserForm: 'fetch',
  }),
  ...assetActions({
    fetchAssetForm: 'fetch',
  }),
  ...feedbackActions({
    fetchFeedbackForm: 'fetch',
  }),
  ...productActions({
    fetchProductForm: 'fetch',
  }),
  ...vendorActions({
    fetchVendorForm: 'fetch',
  }),
  ...contractActions({
    fetchContractForm: 'fetch',
  }),
  ...purchaseActions({
    fetchPurchaseForm: 'fetch',
  }),
  // ...callHistoryActions({
  //   // fetchco_ops_call_historyForm: 'fetch',
  // }),
  ...mspCompanyActions({
    fetchmsp_companyForm: 'fetch',
  }),
}
