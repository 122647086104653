import { getSupportPortalConfigApi } from './api'
export const state = {
  config: {},
}

export const getters = {
  allowRegistration(state) {
    return state.config.allowSelfRegistration
  },
  allowGuestToCreateIncident(state) {
    return state.config.allowGuestToReportRequest
  },
  showRelatedKnowledge(state) {
    return state.config.showRelatedKnowledge
  },
  allowToLinkAsset(state) {
    return state.config.allowToLinkAsset
  },
  allowToReopenClosedReq(state) {
    return state.config.allowToReopenClosedReq
  },
  allowToCloseReq(state) {
    return state.config.allowToCloseReq
  },
  closedGraceTimeType(state) {
    return state.config.closedGraceTimeType
  },
  closedGraceTime(state) {
    return state.config.closedGraceTime
  },
  allowToReopenResolvedReq(state) {
    return state.config.allowToReopenResolvedReq
  },
  resolvedGraceTimeType(state) {
    return state.config.resolvedGraceTimeType
  },
  resolvedGraceTime(state) {
    return state.config.resolvedGraceTime
  },
  allowReqToAccessMyAsset(state) {
    return state.config.allowReqToAccessMyAsset
  },
  allowRequesterToViewBarcodeAndQRCode(state) {
    return state.config.allowRequesterToViewBarcodeAndQRCode
  },
  allowRequesterToAccessKB(state) {
    return state.config.allowRequesterToAccessKB
  },
  allowRequesterToAccessServiceRequest(state) {
    return state.config.allowRequesterToAccessServiceRequest
  },
  allowRequesterToAccessMyApprovals(state) {
    return state.config.allowRequesterToAccessMyApprovals
  },
  allowRequesterToSubmitFeedback(state) {
    return state.config.allowRequesterToSubmitFeedback
  },
  allowRequesterToAccestTicketApprovalTab(state) {
    return state.config.allowRequesterToAccestTicketApprovalTab
  },
  selfServiceSetting(state) {
    return state.config.selfServiceSetting
  },
  allowReqToAccessMyChanges(state) {
    return state.config.allowReqToAccessMyChanges
  },
  allowRequesterToCreateRequestBehalfOfOtherRequester(state) {
    return state.config.allowRequesterToCreateRequestBehalfOfOtherRequester
  },
  allowRequesterToCreateIncidentBehalfOfOtherRequester(state) {
    return state.config.allowRequesterToCreateIncidentBehalfOfOtherRequester
  },
}

export const mutations = {
  SET_CONFIG(state, payload) {
    state.config = payload
  },
}

export const actions = {
  init({ dispatch }) {
    dispatch('fetch')
  },

  fetch({ commit }, params) {
    return getSupportPortalConfigApi(params).then((data) => {
      commit('SET_CONFIG', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // perform state clean up here when user is logged out
  },
}
