import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('branding')

export const BrandingComputed = {
  ...mapGetters({
    branding: 'branding',
    motadataCompanyLogo: 'logo',
    companyFavicon: 'favicon',
    darkThemeLogo: 'darkThemeLogo',
  }),
}

export const BrandingMethods = mapActions({
  updateBranding: 'updateBranding',
  fetchBranding: 'fetch',
})
