import Omit from 'lodash/omit'
import { getBrandingApi, updateBrandingApi } from './api'
export const state = {
  branding: getSavedState('branding'),
}

export const getters = {
  branding(state) {
    return state.branding
  },
  logo(state) {
    return state.branding.logoSrc
  },
  darkThemeLogo(state) {
    return state.branding.darkThemeLogoSrc
  },
  favicon(state) {
    return state.branding.faviconSrc
  },
}

export const mutations = {
  SET_BRANDING(state, payload) {
    state.branding = payload
    saveState('branding', payload)
  },
}

export const actions = {
  init({ dispatch }) {
    dispatch('fetch')
  },

  fetch({ commit }, params) {
    return getBrandingApi(params).then((data) => {
      commit('SET_BRANDING', data)
    })
  },

  updateBranding({ commit }, payload) {
    commit(
      'SET_BRANDING',
      Omit(payload, ['logoSrc', 'darkThemeLogoSrc', 'faviconSrc'])
    )
    return updateBrandingApi(payload).then((data) => {
      commit('SET_BRANDING', data)
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    // commit('SET_BRANDING', {})
  },
}

export function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
