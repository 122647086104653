import Vue from 'vue'
import antDirectives from 'ant-design-vue/es/_util/antDirective'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import PortalVue from 'portal-vue'
import VTooltip from 'v-tooltip'
import Constants from '@constants'
import Http from '@plugins/http'
import Modular from '@plugins/modular'
import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

Vue.use(PortalVue)
Vue.use(Http)
Vue.use(Modular)
Vue.use(VTooltip)
Vue.use(antDirectives)
Vue.component('MValidationObserver', ValidationObserver)
Vue.component('MValidationProvider', ValidationProvider)
Vue.component('RecycleScroller', RecycleScroller)

Vue.use(function (vue) {
  vue.mixin({
    beforeCreate() {
      this.$constants = Constants
      // apply notification classes

      const originalErrorNotification = this.$errorNotification

      this.$errorNotification = (args = {}) =>
        originalErrorNotification({
          ...args,

          class: 'error-notification',

          duration: 5,
        })

      const originalSuccessNotification = this.$successNotification

      this.$successNotification = (args = {}) =>
        originalSuccessNotification({ ...args, class: 'success-notification' })
    },
  })
})
